
        <template>
          <div class="vc-snippet-doc">
            <h2>PageTemplate 表格容器</h2>
<h3>基础用法</h3>
<p>基础的用法。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">style</span>=<span class="hljs-string">"height: 300px"</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-page-template</span> <span class="hljs-attr">:pageSize</span>=<span class="hljs-string">"0"</span>
        <span class="hljs-attr">tip</span>=<span class="hljs-string">"暂无数据，亲"</span> &gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>666<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-page-template</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>pageSize</td>
<td>数据长度</td>
<td>Number</td>
<td>—</td>
<td></td>
</tr>
<tr>
<td>tip</td>
<td>提示文字</td>
<td>String</td>
<td>—</td>
<td><code>暂无数据</code></td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"300px"}},[_c('ixu-page-template',{attrs:{"pageSize":0,"tip":"暂无数据，亲"}},[_c('div',[_vm._v("666")])])],1)}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>